import React, { useEffect, useState, useRef } from "react";
import Images from "./Images"; // Ensure the correct path for your Images module
import CountUp from "react-countup"; // Ensure the correct import for CountUp

const Aboutus = () => {
  const [startCounting, setStartCounting] = useState(false);
  const counterRef = useRef(null); // Ref to the counter area

  // Observer to trigger counting when the counter area comes into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCounting(true); // Start counting when the counter area is visible
          observer.disconnect(); // Stop observing after it enters view
        }
      },
      { threshold: 0.5 } // Trigger when 50% of the counter area is visible
    );

    if (counterRef.current) {
      observer.observe(counterRef.current); // Start observing the counter area
    }

    return () => {
      observer.disconnect(); // Clean up the observer on unmount
    };
  }, []);

  return (
    <div className="about7-section-area sp1" id="aboutus">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 m-auto">
            <div className="about-header text-center heading12">
              <h5>About Us</h5>
              <h2 className="text-anime-style-3">
                Digital Mind - Introducing Our Team and Purpose at the Digital Marketing Agency
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <div className="about-pera-list">
              <p data-aos="fade-right" data-aos-duration="800">
                At our agency, we go beyond standard services to become your digital growth partner. With years of industry experience and a commitment to innovation, we specialize in digital marketing, SEO optimization, and tailored marketing strategies.
              </p>
              <div className="space8"></div>
              <div data-aos="fade-right" data-aos-duration="1000">
                <ul>
                  <li><img src={Images.check11} alt="Meta Ads Management Biharsharif" />Digital Marketing</li>
                  <li><img src={Images.check11} alt="Google Ads Services Biharsharif" />Web Designing & Development</li>
                  <li><img src={Images.check11} alt="Facebook Ads Biharsharif" />SEO (Search Engine Optimization)</li>
                  <li><img src={Images.check11} alt="Facebook Ads Biharsharif" />Social Media Marketing</li>
                  <li><img src={Images.check11} alt="Instagram Ads Biharsharif" />Mobile App Development</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-lg-7">
            <div className="about-images" ref={counterRef}>
              <div className="row">
                <div className="col-lg-6 col-md-6">
                  <div className="img1">
                    <img src={Images.about_img7} alt="Paid Advertising Biharsharif" />
                    <div className="counter-area">
                      <h2>
                        {startCounting ? (
                          <CountUp end={450} duration={5} separator="" />
                        ) : (
                          '0'
                        )}
                        +
                      </h2>
                      {startCounting && <p>Happy Clients</p>}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6" data-aos="zoom-in" data-aos-duration="1000">
                  <div className="space80 d-lg-block d-none"></div>
                  <div className="space30 d-lg-none d-block"></div>
                  <div className="img2">
                    <img src={Images.about_img8} alt="Google PPC Campaigns Biharsharif" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-2">
            <div className="counter-boxarea">
              <div className="row">
                <div className="col-lg-12 col-md-6 col-6">
                  <div className="counter-box" data-aos="zoom-out" data-aos-duration="800">
                    <h2>
                      {startCounting ? <CountUp end={15} duration={5} separator="" /> : '0'}+
                    </h2>
                    <p>Year In Business</p>
                  </div>
                </div>

                <div className="col-lg-12 col-md-6 col-6">
                  <div className="counter-box" data-aos="zoom-out" data-aos-duration="1000">
                    <h2>
                      {startCounting ? <CountUp end={48} duration={5} separator="" /> : '0'}K
                    </h2>
                    <p>Projects Done</p>
                  </div>
                </div>

                <div className="col-lg-12 col-md-6 col-6">
                  <div className="counter-box" data-aos="zoom-out" data-aos-duration="1100">
                    <h2>
                      {startCounting ? <CountUp end={95} duration={5} separator="" /> : '0'}K+
                    </h2>
                    <p>Hours Worked</p>
                  </div>
                </div>

                <div className="col-lg-12 col-md-6 col-6">
                  <div className="counter-box" data-aos="zoom-out" data-aos-duration="1200">
                    <h2>
                      {startCounting ? <CountUp end={80} duration={5} separator="" /> : '0'}+
                    </h2>
                    <p>Team Members</p>
                  </div>
                </div>

                <div className="col-lg-12 col-md-6 col-6">
                  <div className="counter-box" data-aos="zoom-out" data-aos-duration="1200">
                    <h2>24/7</h2>
                    <p>Support Available</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
