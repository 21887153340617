import React, { useState,useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Casearea = () => {
  const [activeCsHover, setActiveCsHover] = useState(null);

   // Initialize AOS once when the component mounts
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration: 1000,
    });
  }, []);

  // Reinitialize AOS after every hover state change to reapply classes
  useEffect(() => {
    AOS.refresh();  // Refresh AOS to reapply aos-init and aos-animate classes
  }, [activeCsHover]);

  const handleCsHover = (index) => {
    setActiveCsHover(index);
  };
  return (
    <div className="case1-section-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 m-auto">
            <div className="case-header-area heading2 text-center">
              <img src={require("../assets/img/elements/star2.png")} alt="Meta Ads Specialist Biharsharif" className="star2 keyframe5"/>
              <img src={require("../assets/img/elements/star2.png")} alt="Google AdWords Expert Biharsharif" className="star3 keyframe5"/>
              <h3 className="text-anime-style-3">Benefits of SEO & Digital Marketing</h3>
              <p data-aos="fade-up" data-aos-duration="1000">By investing in strategic SEO and digital marketing initiatives, businesses can <br className="d-md-block d-none"/> unlock a myriad of benefits.</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12" data-aos="zoom-out" data-aos-duration="1200">
              <div className="cs_case_study_1_list">
                <div className={`cs_case_study cs_style_1 cs_hover_active ${activeCsHover === 0 ? 'active' : ''}`} onMouseEnter={() => handleCsHover(0)} data-aos="fade-up" data-aos-duration="800">
                  <span className="cs_case_study_thumb cs_bg_filed" data-src={require("../assets/img/all-images/case-img1.webp")}></span>
                  <div className="content-area1">
                    <span>Website  Design & Development</span>
                  </div>
                  <div className="content-area">
                    <span>Website  Design & Development </span>
                    <p>We understand the critical role that a well-designed and user-friendly website plays in shaping your online presence driving.</p>
                  </div>
                </div>
                <div className={`cs_case_study cs_style_1 cs_hover_active ${activeCsHover === 1 ? 'active' : ''}`} onMouseEnter={() => handleCsHover(1)} data-aos="fade-up" data-aos-duration="900">
                  <span className="cs_case_study_thumb cs_case_study_thumb7 cs_bg_filed" data-src={require("../assets/img/all-images/case-img7.webp")}></span>
                  <div className="content-area1">
                    <span>Digital Marketing</span>
                  </div>
                  <div className="content-area">
                    <span>Digital Marketing </span>
                    <p>We understand the critical role that a well-designed and user-friendly website plays in shaping your online presence driving.</p>
                  </div>
                </div>
                <div className={`cs_case_study cs_style_1 cs_hover_active ${activeCsHover === 2 ? 'active' : ''}`} onMouseEnter={() => handleCsHover(2)} data-aos="fade-up" data-aos-duration="1000">
                  <span className="cs_case_study_thumb cs_case_study_thumb2 cs_bg_filed" data-src={require("../assets/img/all-images/case-img2.webp")}></span>
                  <div className="content-area1">
                    <span>SEO</span>
                  </div>
                  <div className="content-area">
                    <span>SEO</span>
                    <p>We understand the critical role that a well-designed and user-friendly website plays in shaping your online presence driving.</p>
                  </div>
                </div>
                <div className={`cs_case_study cs_style_1 cs_hover_active ${activeCsHover === 3 ? 'active' : ''}`} onMouseEnter={() => handleCsHover(3)} data-aos="fade-up" data-aos-duration="1100">
                  <span className="cs_case_study_thumb cs_case_study_thumb3 cs_bg_filed" data-src={require("../assets/img/all-images/case-img3.webp")}></span>
                  <div className="content-area1">
                    <span>PPC Advertising</span>
                  </div>
                  <div className="content-area">
                    <span>PPC Advertising</span>
                    <p>We understand the critical role that a well-designed and user-friendly website plays in shaping your online presence driving.</p>
                  </div>
                </div>
                <div className={`cs_case_study cs_style_1 cs_hover_active ${activeCsHover === 4 ? 'active' : ''}`} onMouseEnter={() => handleCsHover(4)} data-aos="fade-up" data-aos-duration="1200">
                  <span className="cs_case_study_thumb cs_case_study_thumb4 cs_bg_filed" data-src={require("../assets/img/all-images/case-img4.webp")}></span>
                  <div className="content-area1">
                    <span>Social Media Marketing</span>
                  </div>
                  <div className="content-area">
                    <span>Social Media Marketing</span>
                    <p>We understand the critical role that a well-designed and user-friendly website plays in shaping your online presence driving.</p>
                  </div>
                </div>
                <div className={`cs_case_study cs_style_1 cs_hover_active ${activeCsHover === 5 ? 'active' : ''}`} onMouseEnter={() => handleCsHover(5)} data-aos="fade-up" data-aos-duration="1300">
                  <span className="cs_case_study_thumb cs_case_study_thumb5 cs_bg_filed" data-src={require("../assets/img/all-images/case-img5.webp")}></span>
                  <div className="content-area1">
                    <span>Content Marketing</span>
                  </div>
                  <div className="content-area">
                    <span>Content Marketing</span>
                    <p>We understand the critical role that a well-designed and user-friendly website plays in shaping your online presence driving.</p>
                  </div>
                </div>
                <div className={`cs_case_study cs_style_1 cs_hover_active ${activeCsHover === 6 ? 'active' : ''}`} onMouseEnter={() => handleCsHover(6)} data-aos="fade-up" data-aos-duration="1400">
                  <span className="cs_case_study_thumb cs_case_study_thumb6 cs_bg_filed" data-src={require("../assets/img/all-images/case-img6.webp")}></span>
                  <div className="content-area1">
                    <span>Email Marketing</span>
                  </div>
                  <div className="content-area">
                    <span>Email Marketing</span>
                    <p>We understand the critical role that a well-designed and user-friendly website plays in shaping your online presence driving.</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const Team = () => {
  return (
    <div className="team-inner-section-area sp1" id="team">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 m-auto">
          <div className="team2-header-area text-center heading2">
            <h5>Our Team</h5>
            <h3>Meet With Our Expert Team</h3>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-1.webp")} alt="Shahbaaz Alam"/>
            </div>
            <div className="content">
              <span>Shahbaaz Alam</span>
              <p>CEO & Founder</p>
            </div>
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-2.webp")} alt="Md Danish"/>
            </div>
            <div className="content">
              <span>Md Danish</span>
              <p>Senior Marketing Manager ( Bihar )</p>
            </div>
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-11.webp")} alt="Shweta"/>
            </div>
            <div className="content">
              <span>Ruhul Afsar</span>
              <p>HR & Accountant ( Dubai )</p>
            </div>
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-10.webp")} alt="Syed Faizan Ahmed"/>
            </div>
            <div className="content">
              <span>Syed Faizan Ahmed</span>
              <p>Marketing Manager ( Dubai )</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-3.webp")} alt="Parwez Khan"/>
            </div>
            <div className="content">
              <span>Parwez Khan</span>
              <p>Marketing Manager</p>
            </div>
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-4.webp")} alt="Priyanka Singh"/>
            </div>
            <div className="content">
              <span>Priyanka Singh</span>
              <p>Professional Web Designer</p>
            </div>
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-5.webp")} alt="Shahzad Alam"/>
            </div>
            <div className="content">
              <span>Shahzad Alam</span>
              <p>Digital Marketing Manager</p>
            </div>
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-6.webp")} alt="Mr. Sam"/>
            </div>
            <div className="content">
              <span>Mr. Sam</span>
              <p>Senior SEO Expert</p>
            </div>
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-7.webp")} alt="Md Nazish Khan"/>
            </div>
            <div className="content">
              <span>Md Nazish Khan</span>
              <p>Sr. Team Leader</p>
            </div>
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-8.webp")} alt="Mr. Saif"/>
            </div>
            <div className="content">
              <span>Mr. Saif</span>
              <p>Photo Editing Expert</p>
            </div>
          </div>
        </div>
  
        <div className="col-lg-3 col-md-6">
          <div className="team-boxarea">
            <div className="img1">
              <img src={require("../assets/img/team/team-9.webp")} alt="Shweta"/>
            </div>
            <div className="content">
              <span>Shweta</span>
              <p>Social Media Expert</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    )
}

export {Casearea,Team};
