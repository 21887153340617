import React,{useEffect,useState} from "react";
import Head from "./Head";
import Images from "./Images";
import { Link as ScrollLink } from 'react-scroll';

const Header = () => {
	const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      if (scroll < 1) {
        setIsSticky(false);
      } else {
        setIsSticky(true);
      }
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Detach the scroll event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
	return (
		<>
		<iframe className="gtm-iframe" title="Google Tag Manager" src="https://www.googletagmanager.com/ns.html?id=GTM-MZRD57M8"></iframe>
		<Head pageTitle="Digital Mind Best Web Designing & SEO Company in Biharsharif" pageDescription="Digital Mind™️ is a leading digital marketing company based in UAE, Dubai, Delhi/Noida, Biharsharif, and Patna specialized in web design, SEO & SMO services" />
		<header>
		    <div className={`${isSticky ? 'sticky' : ''} header-area homepage1 header header-sticky d-none d-lg-block`} id="header">
		      <div className="container">
		        <div className="row">
		          <div className="col-lg-12">
		            <nav id="navbar-example2" className="navbar">
			            <div className="header-elements">
			              <div className="site-logo">
			                <a href="/">
								        <picture>
													<source type="image/webp" srcset={Images.logo1} />
													<img src={Images.logo1} alt="Digital Mind" />
												</picture>
		                	</a>
			              </div>
			              <div className="main-menu">
			                <ul>
			                    <li className="nav-item"><ScrollLink to="hero1" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Home</ScrollLink></li>
			                    <li className="nav-item"><ScrollLink to="aboutus" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">About</ScrollLink></li>
			                    <li className="nav-item"><ScrollLink to="service"  spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Services</ScrollLink></li>
			                    <li className="nav-item"><ScrollLink to="work"  spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Works</ScrollLink></li>
			                    <li className="nav-item"><ScrollLink to="team"  spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Team</ScrollLink></li>
			                    <li className="nav-item"><ScrollLink to="testimonial"  spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Testimonial</ScrollLink></li>
			                    <li className="nav-item"><ScrollLink to="contact"  spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Contact</ScrollLink></li>
			                </ul>
			              </div>
			              <div className="btn-area">
			                <a href="https://api.whatsapp.com/send?phone=917488428886" target="_blank" rel="noreferrer" className="header-btn1">Chat With Us <span><i className="fa-brands fa-whatsapp"></i></span></a>
			              </div>
			            </div>
		        		</nav> 
		          </div>
		        </div>
		      </div>
		    </div>
		  </header>
		  <div className="mobile-header mobile-haeder1 d-block d-lg-none">
			  <div className="container-fluid">
			    <div className="col-12">
			      <div className="mobile-header-elements">
			        <div className="mobile-logo">
                <a href="/">
					        <picture>
										<source type="image/webp" srcset={Images.logo1} />
										<img src={Images.logo1} alt="Digital Mind" />
									</picture>
              	</a>
			        </div>
			        <div className="mobile-nav-icon dots-menu" onClick={handleToggle}>
			          <i className="fa-solid fa-bars"></i>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<div className={`mobile-sidebar mobile-sidebar1 ${isOpen ? 'mobile-menu-active' : ''}`}>
			  <div className="logosicon-area">
			    <div className="logos">
		        <picture>
							<source type="image/webp" srcset={Images.logo1} />
							<img src={Images.logo1} alt="Digital Mind" />
						</picture>
			    </div>
			    <div className="menu-close"  onClick={handleToggle}>
			      <i className="fa-solid fa-xmark"></i>
			    </div>
			   </div>
			  <div className="mobile-nav mobile-nav1">
			    <ul className="mobile-nav-list nav-list1">
			      <li><ScrollLink to="/" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Home </ScrollLink></li>
			      <li className="nav-item">
              <ScrollLink to="aboutus" spy={true} smooth={true} offset={-70} duration={500} className="nav-link">About</ScrollLink>
            </li>
			      <li className="nav-item"><ScrollLink to="service"  spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Services</ScrollLink></li>
            <li className="nav-item"><ScrollLink to="work"  spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Works</ScrollLink></li>
            <li className="nav-item"><ScrollLink to="team"  spy={true} smooth={true} offset={-70} duration={500} className="nav-link">Team</ScrollLink></li>
        	</ul>

			    <div className="allmobilesection">
			    	<ScrollLink to="contact"  spy={true} smooth={true} offset={-70} duration={500} className="header-btn1">Get Started <span><i className="fa-solid fa-arrow-right"></i></span></ScrollLink>
			      <div className="single-footer">
			        <h3>Contact Info</h3>
			        <div className="footer1-contact-info">
			          <div className="contact-info-single">
			            <div className="contact-info-icon">
			              <i className="fa-solid fa-phone-volume"></i>
			            </div>
			            <div className="contact-info-text">
			              <a href="tel:+917488428886">+91-7488428886</a>
			            </div>
			          </div>

			          <div className="contact-info-single">
			            <div className="contact-info-icon">
			              <i className="fa-solid fa-envelope"></i>
			            </div>
			            <div className="contact-info-text">
			              <a href="mailto:info@ourdigitalmind.com">info@ourdigitalmind.com</a>
			            </div>
			          </div>

			          <div className="single-footer">
			            <h3>Our Location</h3>
			            
			            <div className="contact-info-single">
			              <div className="contact-info-icon">
			                <i className="fa-solid fa-location-dot"></i>
			              </div>
			              <div className="contact-info-text">
			                <a href="/" >A-60, 2nd Floor, Sector 2, Near Sector 15 Metro, Noida <br/> Uttar Pradesh - 201301</a>
			              </div>
			            </div>
			          </div>
			        </div>
			      </div>
			     </div>
			  </div>
			</div>
		</>
	)
}
export default Header;
