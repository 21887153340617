import logo1 from '../assets/img/logo/digi_logo.png';
import searchicons1 from '../assets/img/icons/search-icons1.svg';
import logoicons from '../assets/img/icons/logo-icons.svg';
import preloader from '../assets/img/logo/preloader.png';
import elements1 from '../assets/img/elements/elements1.webp';
import headerimg from '../assets/img/all-images/header-img1.webp';
import headerimgbg from '../assets/img/bg/header-imgbg.png';
import soundicon from '../assets/img/icons/sound-icons1.svg';
import liteicon from '../assets/img/icons/lite-icons1.svg';
import elements2 from '../assets/img/elements/elements2.png';
import elements3 from '../assets/img/elements/elements3.png';
import hedaerauthorimg1 from '../assets/img/all-images/header-author-img1.png';
import hedaerauthorimg2 from '../assets/img/all-images/header-author-img2.png';
import web_design from '../assets/img/all-images/web_design.png';
import facebook_ads from '../assets/img/all-images/facebook_ads.png';
import youtube_ads from '../assets/img/all-images/youtube_ads.png';
import instagram_ads from '../assets/img/all-images/instagram_ads.png';
import social_media_ads from '../assets/img/all-images/social_media_ads.png';
import mobile_app_development from '../assets/img/all-images/mobile_app_development.png';
import about_img7 from '../assets/img/all-images/about-img7.webp';
import about_img8 from '../assets/img/all-images/about-img8.webp';
import search_engine_optimization from '../assets/img/all-images/search-engine-optimization.png';
import pay_per_click from '../assets/img/all-images/pay-per-click.png';
import social_media_marketing from '../assets/img/all-images/social-media-marketing.png';
import headerbg from '../assets/img/bg/header-bg1.png';
import pagebg from '../assets/img/bg/pages-bg1.png';
import arrow from '../assets/img/icons/arrow.svg';
import serviceicon1 from '../assets/img/icons/service-icon1.svg';
import serviceicon2 from '../assets/img/icons/service-icon2.svg';
import serviceicon3 from '../assets/img/icons/service-icon3.svg';
import serviceicon4 from '../assets/img/icons/service-icon4.svg';
import google from '../assets/img/icons/google.svg';
import contact1 from '../assets/img/icons/contact1.svg';
import calender1 from '../assets/img/icons/calender1.svg';
import location2 from '../assets/img/icons/location2.svg';
import location from '../assets/img/icons/location.svg';
import email2 from '../assets/img/icons/email2.svg';
import email from '../assets/img/icons/email.svg';
import phone2 from '../assets/img/icons/phone2.svg';
import phone from '../assets/img/icons/phone.svg';
import facebook from '../assets/img/icons/facebook.svg';
import instagram from '../assets/img/icons/instagram.svg';
import linkedin from '../assets/img/icons/linkedin.svg';
import youtube from '../assets/img/icons/youtube.svg';
import check11 from '../assets/img/icons/check11.svg';
const Images = {
    logo1,
    searchicons1,
    logoicons,
    preloader,
    elements1,
    headerimg,
    headerimgbg,
    soundicon,
    liteicon,
    elements2,
    elements3,
    hedaerauthorimg1,
    hedaerauthorimg2,
    web_design,
    search_engine_optimization,
    pay_per_click,
    social_media_marketing,
    headerbg,
    pagebg,
    arrow,
    serviceicon1,
    serviceicon2,
    serviceicon3,
    serviceicon4,
    google,
    contact1,
    calender1,
    location2,
    location,
    email2,
    email,
    phone2,
    phone,
    facebook,
    instagram,
    linkedin,
    youtube,
    check11,
    about_img7,
    about_img8,
    facebook_ads,
    youtube_ads,
    instagram_ads,
    social_media_ads,
    mobile_app_development,
}
export default Images;