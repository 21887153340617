import React,{useRef} from "react";
import Images from "./Images";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 0,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="slider-section-area sp5">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-2">
            <div className="sldier-head">
              <p>
                Trusted by <br className="d-lg-block d-none" /> Top Companies
              </p>
            </div>
          </div>
          <div className="col-lg-10">
            <Slider {...settings}>
              <div className="item">
                <img src={require("../assets/img/elements/brand-img1.png")} alt="Google Ads Services Biharsharif" />
              </div>
              <div className="item">
                <img src={require("../assets/img/elements/brand-img2.png")} alt="Facebook Ads Biharsharif" />
              </div>
              <div className="item">
                <img src={require("../assets/img/elements/brand-img3.png")} alt="Instagram Ads Biharsharif" />
              </div>
              <div className="item">
                <img src={require("../assets/img/elements/brand-img4.png")} alt="Paid Advertising Biharsharif" />
              </div>
              <div className="item">
                <img src={require("../assets/img/elements/brand-img5.png")} alt="Google PPC Campaigns Biharsharif" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};
const MapEmbed = () => {
  return (
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14013.754464237425!2d77.3149135!3d28.5866157!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5a6d22d8b8f%3A0xcb050fc3a26ae9b4!2sDigital%20Mind%20-%20Web%20Designing%20Company%20Noida%20Delhi%2FNCR!5e0!3m2!1sen!2sin!4v1728584753920!5m2!1sen!2sin"
        width="100%"
        height="300px"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Digital Mind"
      ></iframe>
  );
};
const HindPlazaMap = () => {
  return (
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7215.924517986314!2d55.297424!3d25.271855!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43a5cb88b4c1%3A0x86188ff8da527ec5!2sHIND%20PLAZA%2C%20BUILDING%20NO.8!5e0!3m2!1sen!2suk!4v1728672932251!5m2!1sen!2suk"
        width="100%"
        height="300px"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Digital Mind"
      ></iframe>
  );
};

const DigitalMindMap = () => {
  return (
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14439.196910845129!2d85.5309205!3d25.2099924!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1cd9dc53dd54f89%3A0xeab0e9669d9ef664!2zRGlnaXRhbCBNaW5k4oSi!5e0!3m2!1sen!2sin!4v1728841389528!5m2!1sen!2sin"
        width="100%"
        height="300px"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Digital Mind"
      ></iframe>
  );
};
const Testimonial2 = () => {
  return (
    <div className="testimonial1-section-area sp6 pb-6" id="contact">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 m-auto">
            <div className="testimonial-header heading2 text-center">
              <img src={require("../assets/img/elements/star2.png")} alt="Meta Ads Specialist Biharsharif" className="star2 keyframe5"/>
              <img src={require("../assets/img/elements/star2.png")} alt="Google AdWords Expert Biharsharif" className="star3 keyframe5"/>
              <h3 className="text-anime-style-3">Get In Touch With Us Today</h3>
              <p data-aos="fade-up" data-aos-duration="1000"></p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="container">
            <div className="row">

              <div className="testimonial-boxarea col-md-4">
                <div className="">
                  <div className="col-lg-12 p-2 border">
                    <HindPlazaMap />
                    <div className="contact-info-area">
                      <div className="row my-2">
                        <div className="col-3 filter-invert">
                          <img src={Images.location2} alt="Online Advertising Biharsharif" className="ps-3"/>
                        </div>
                        <div className="col-9 ps-0">
                          <h5>Marketing Office - UAE</h5>
                          <p className="addrstag">Hind Plaza, Deira - Al Ras,<br className="d-lg-block d-none"/>Dubai - United Arab Emirates</p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3 filter-invert">
                          <img src={Images.phone2} alt="Meta and Google Ads Optimization Biharsharif" className="ps-3"/>
                        </div>
                        <div className="col-9 ps-0">
                          <h5>Phone Number</h5>
                          <a href="tel:+971567451246" className="addrstag">+971 56 745 1246</a>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3 filter-invert">
                          <img src={Images.email2} alt="Meta and Google Ads Optimization Biharsharif" className="ps-3"/>
                        </div>
                        <div className="col-9 ps-0">
                          <h5>Email Address</h5>
                          <a href="mailto:uae@ourdigitalmind.com" className="addrstag">uae@ourdigitalmind.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="testimonial-boxarea col-md-4">
                <div className="">
                  <div className="col-lg-12 p-2 border">
                    <MapEmbed />
                    <div className="contact-info-area">
                      <div className="row my-2">
                        <div className="col-3 filter-invert">
                          <img src={Images.location2} alt="Number Website Company Biharsharif" className="ps-3"/>
                        </div>
                        <div className="col-9 ps-0">
                          <h5>Development Office</h5>
                          <p className="addrstag">A-60, 2nd Floor, Sector 2, Near Sector 15 Metro,<br className="d-lg-block d-none"/> Noida, Uttar Pradesh - 201301</p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3 filter-invert">
                          <img src={Images.phone2} alt="Online Advertising Biharsharif" className="ps-3"/>
                        </div>
                        <div className="col-9 ps-0">
                          <h5>Phone Number</h5>
                          <a href="tel:+917488428886" className="addrstag">+91-7488428886</a>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3 filter-invert">
                          <img src={Images.email2} alt="Meta Ads Specialist Biharsharif" className="ps-3"/>
                        </div>
                        <div className="col-9 ps-0">
                          <h5>Email Address</h5>
                          <a href="mailto:noida@ourdigitalmind.com" className="addrstag">noida@ourdigitalmind.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="testimonial-boxarea col-md-4">
                <div className="">
                  <div className="col-lg-12 p-2 border">
                    <DigitalMindMap />
                    <div className="contact-info-area">
                      <div className="row my-2">
                        <div className="col-3 filter-invert">
                          <img src={Images.location2} alt="Instagram Ads Biharsharif" className="ps-3"/>
                        </div>
                        <div className="col-9 ps-0">
                          <h5>Digital Mind - Bihar Office</h5>
                          <p className="addrstag">Mohalla Chajju,<br className="d-lg-block d-none"/> Bihar Sharif, Bihar 803101</p>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3 filter-invert">
                          <img src={Images.phone2} alt="Paid Advertising Biharsharif" className="ps-3"/>
                        </div>
                        <div className="col-9 ps-0">
                          <h5>Phone Number</h5>
                          <a href="tel:+917488428886" className="addrstag">+91-7488428886</a>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-3 filter-invert">
                          <img src={Images.email2} alt="Google PPC Campaigns Biharsharif" className="ps-3"/>
                        </div>
                        <div className="col-9 ps-0">
                          <h5>Email Address</h5>
                          <a href="mailto:bihar@ourdigitalmind.com" className="addrstag">bihar@ourdigitalmind.com</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Testimonial3 = () => {
  const mainSlider = useRef(null);

  // Slider settings for the main testimonials
  const mainSettings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    // fade: true,
  };

  return (
    <>
      <div className="testimonial5-section-area sp1" id="testimonial">
        <div className="container">
          <div className="row">
            <div className="col-lg-7 m-auto">
              <div className="testimonial-header-area text-center heading10">
                <h5><img src={require("../assets/img/icons/logo-icons5.svg")} alt="" />Testimonials</h5>
                <h2 className="text-anime-style-3">Client Success Stories: Hear What Our Clients Have To Say</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="testimonial-sliders text-center" data-aos="zoom-out" data-aos-duration="1000">

                <Slider ref={mainSlider} {...mainSettings} className="slider-testimonial">
                  <div className="single-testimonial">
                    <h5>“Digital Mind revamped our website beautifully! It’s sleek and user-friendly. Their SEO services boosted us to the top pages, and their social media strategy is a game-changer for our brand. Highly recommend!"</h5>
                    <div className="author">
                      <span>Keith Newman</span>
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <h5>“Excellent experience with Digital Mind! Their website design is stunning, and we saw a big boost in traffic thanks to their SEO work. Our social media engagement has never been higher. Very pleased!"</h5>
                    <div className="author">
                      <span>Robert Beebe</span>
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <h5>“Digital Mind is incredible! They designed a clean, modern website, improved our SEO ranking, and took our social media to the next level. Our online presence has never been stronger!"</h5>
                    <div className="author">
                      <span>Carolina Lizarazo</span>
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <h5>“Since partnering with Digital Mind, our website looks fantastic, and our search rankings have soared! Their social media team knows exactly how to engage our audience. Highly impressed!"</h5>
                    <div className="author">
                      <span>Hilary Anderson</span>
                    </div>
                  </div>
                  <div className="single-testimonial">
                    <h5>“Digital Mind transformed our digital presence! Our new website is stunning, SEO performance is up, and their social media expertise has brought in tons of engagement. Couldn’t ask for better results!"</h5>
                    <div className="author">
                      <span>Mike Hakimi</span>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export {Testimonials, Testimonial2, Testimonial3};
