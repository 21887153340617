import React from 'react';
import { Helmet } from 'react-helmet';
import Images from './Images';

const Head = ({ pageTitle, pageDescription }) => {
  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "LocalBusiness",
        "name": "Digital Mind - Marketing Office - UAE",
        "image": Images.logo1,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Hind Plaza, Deira - Al Ras",
          "addressLocality": "Dubai",
          "addressCountry": "AE"
        },
        "telephone": "+971 56 745 1246",
        "email": "uae@ourdigitalmind.com",
        "openingHours": ["Mo-Su 09:00-20:00"],
        "url": process.env.PUBLIC_URL,
        "additionalType": "https://schema.org/MarketingAgency"
      },
      {
        "@type": "LocalBusiness",
        "name": "Digital Mind - Development Office",
        "image": Images.logo1,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "A-60, 2nd Floor, Sector 2, Near Sector 15 Metro",
          "addressLocality": "Noida",
          "addressRegion": "Uttar Pradesh",
          "postalCode": "201301",
          "addressCountry": "IN"
        },
        "telephone": "+91-7488428886",
        "email": "noida@ourdigitalmind.com",
        "openingHours": ["Mo-Su 09:00-20:00"],
        "url": process.env.PUBLIC_URL,
        "additionalType": "https://schema.org/DevelopmentAgency"
      },
      {
        "@type": "LocalBusiness",
        "name": "Digital Mind - Bihar Office",
        "image": Images.logo1,
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Mohalla Chajju",
          "addressLocality": "Bihar Sharif",
          "addressRegion": "Bihar",
          "postalCode": "803101",
          "addressCountry": "IN"
        },
        "telephone": "+91-7488428886",
        "email": "bihar@ourdigitalmind.com",
        "openingHours": ["Mo-Su 09:00-20:00"],
        "url": process.env.PUBLIC_URL,
        "additionalType": "https://schema.org/Business"
      }
    ]
  };

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <link rel="canonical" href={process.env.PUBLIC_URL} />

      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      <meta property="og:image" content={Images.logo1} />
      <meta property="og:image:alt" content={pageTitle} />
      <meta property="og:url" content={process.env.PUBLIC_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Digital Mind" />
      <meta property="og:locale" content="en_US" />

      <meta name="twitter:title" content={pageTitle} />
      <meta name="twitter:description" content={pageDescription} />
      <meta name="twitter:image" content={Images.logo1} />
      <meta name="twitter:card" content="summary" />
      <script type="application/ld+json">
        {JSON.stringify(jsonLd)}
      </script>
      <script>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-MZRD57M8');`}
      </script>

      <script async src="https://www.googletagmanager.com/gtag/js?id=G-GNLB37EW0Y"></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-GNLB37EW0Y');
        `}
      </script>
    </Helmet>
  );
};

export default Head;
