import React, { useEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Splitting from 'splitting';
import Images from "./Images";
import 'splitting/dist/splitting.css';

gsap.registerPlugin(ScrollTrigger);

const TextAnimation = () => {
  useEffect(() => {
    Splitting({ target: '.text-anime-style-3', by: 'chars' });

    const textStyle3Elements = document.querySelectorAll('.text-anime-style-3');
    if (textStyle3Elements.length > 0) {
      textStyle3Elements.forEach((element) => {
        const chars = element.querySelectorAll('.char');
        gsap.set(chars, { opacity: 0, x: 50 });

        gsap.to(chars, {
          scrollTrigger: {
            trigger: element,
            start: 'top 90%',
          },
          x: 0,
          opacity: 1,
          duration: 1,
          ease: 'back.out(1.7)', 
          stagger: 0.02,
        });
      });
    }

    // Cleanup on component unmount
    return () => {
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <h1 className="text-anime-style-3">Grow Your Business with our Effective Digital Marketing Solutions</h1>
  );
};
const CircleComponent = () => {
  return (
    <div className="circle-container">
      <div className="circle-bg">
        <img src={Images.arrow} alt="Digital Strategy Biharsharif" className="arrow"/>
      </div>
      <svg width="200" height="200" className="svg-container">
        <defs>
          <path id="circlePath" d="M 100, 100 m -70, 0 a 70,70 0 1,1 140,0 a 70,70 0 1,1 -140,0" />
        </defs>
        <text fontSize="15" className="rotating-text">
          <textPath href="#circlePath" startOffset="50%">
            Digital Mind™️ - 100% SATISFACTION - GUARANTEED RESULT-
          </textPath>
        </text>
      </svg>
    </div>
  );
};
const RevealComponent = () => {
  useEffect(() => {
    const revealContainers = document.querySelectorAll(".reveal");

    if (revealContainers.length) {
      revealContainers.forEach((container) => {
        let image = container.querySelector("img");
        let tl = gsap.timeline({
          scrollTrigger: {
            trigger: container,
            toggleActions: "play none none none",
          },
        });

        tl.set(container, { autoAlpha: 1 });
        tl.from(container, 1.5, { xPercent: 0, ease: "Power2.out" });
        tl.from(image, 1.5, { xPercent: 100, scale: 1.3, delay: -1.5, ease: "Power2.out" });
      });
    }

    // Cleanup ScrollTrigger instances on unmount
    return () => {
      ScrollTrigger.getAll().forEach(trigger => trigger.kill());
    };
  }, []);

  return (
    <>
    <div className="col-lg-4">
      <div className="about-images">
       <figure className="image-anime reveal">
        <img src={require("../assets/img/all-images/about-img1.webp")} alt="Best Website Designing Company Biharsharif" />
       </figure>
        <img src={require("../assets/img/elements/star1.png")} alt="Top Website Designing Company Biharsharif" className="star1 keyframe5"/>
      </div>
    </div>
    <div className="col-lg-5">
      <div className="about-content-area heading2">
        <CircleComponent />
        <h2 className="text-anime-style-3">All-in-One Web, SEO, and Digital Marketing Solutions to Boost Your Brand's Value!</h2>
        <p data-aos="fade-left" data-aos-duration="1000">At Digital Mind™️, we specialize in web design and development, search engine optimization, and social media marketing on platforms such as Facebook, Instagram, and YouTube. Additionally, we offer online reputation management solutions and proudly serve clients around the globe.</p>
      </div>
    </div>
    <div className="col-lg-3">
      <div className="about-auhtor-images">
        <img src={require("../assets/img/elements/elements5.webp")} alt="Number Website Company Biharsharif" className="elements5 keyframe5"/>
        <figure className="image-anime reveal">
          <img src={require("../assets/img/all-images/about-img2.webp")} alt="Meta Ads Management Biharsharif"/>
         </figure>
      </div>
    </div></>
  );
};

export {TextAnimation,RevealComponent};