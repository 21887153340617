import React from "react";
import PropTypes from "prop-types";
import { Link as ScrollLink } from 'react-scroll';

const ScrollTag = ({ slideTo, classList = "", btnVal = "" }) => {
    return (
        <ScrollLink 
            to={slideTo} 
            spy={true} 
            smooth={true} 
            offset={-70} 
            duration={500} 
            className={classList} 
            style={{ cursor: 'pointer' }}
        >
            {btnVal}
        </ScrollLink>
    );
};

ScrollTag.propTypes = {
    slideTo: PropTypes.string.isRequired,
    classList: PropTypes.string,
    btnVal: PropTypes.string,
};

export default ScrollTag;