import React from "react";
import {Testimonials} from "./Testimonials";
import {RevealComponent} from "./TextAnimation";


const About = () => {
	return (
		<>
		<div data-bs-spy="scroll" data-bs-target="#navbar-example2" data-bs-root-margin="0px 0px -40%" data-bs-smooth-scroll="true" className="scrollspy-example bg-body-tertiary rounded-2" tabIndex="0">
			<Testimonials />
			<div className="all-section-bg">
				<div className="about1-section-area sp6" id="about">
				  <div className="container">
				    <div className="row align-items-center">
				      <RevealComponent/>
				    </div>
				  </div>
				</div>
			</div>
		</div>
		</>
	)
}
export {About};