import React from 'react';
import { Link } from 'react-router-dom';
import Head from './Head'; // Ensure you import your Head component

const NotFound = () => {
  return (
    <>
      <Head 
        pageTitle="Page Not Found - Digital Mind" 
        pageDescription="The page you are looking for does not exist. Return to the homepage or explore our services." 
      />
      <div className="text-center py-5">
        <h1 className="display-4">404 - Page Not Found</h1>
        <p className="lead">Oops! The page you are looking for does not exist.</p>
        <Link className="btn btn-primary" to="/">Go to Home</Link>
      </div>
    </>
  );
};

export default NotFound;
