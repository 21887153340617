import React, {useEffect } from "react";
import AOS from 'aos';
import Images from "./Images";
import ScrollTag from "../components/ScrollTag";

const Service = () => {
  useEffect(() => {
    AOS.init({
      disable: 'mobile',
      duration: 1000,
    });
    const handleScroll = () => {
      AOS.refresh();
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className="service1-section-area sp9" id="service">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-auto">
              <div className="service-header-area heading2 text-center">
                <img src={require("../assets/img/elements/star2.png")} alt="Website Development Biharsharif" className="star2 keyframe5" />
                <img src={require("../assets/img/elements/star2.png")} alt="Digital Advertising Biharsharif" className="star3 keyframe5" />
                <h3 className="text-anime-style-3">Top-Rated Digital Marketing Services <br className="d-md-block d-none" /> Designed to Grow Your Business!</h3>
                <p data-aos="fade-up" data-aos-duration="1000">Our dedicated team is committed to delivering personalized solutions <br className="d-md-block d-none" /> that enhance your brand and drive significant results. </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="service-all-boxes-area">
                <div className="service-boxarea box4" data-aos="zoom-in" data-aos-duration="1400">
                  <span>Website Design and<br /> Development</span>
                  <div className="space40"></div>
                  <img src={Images.web_design} alt="Online Marketing Biharsharif" />
                  <div className="space40"></div>
                  <p>Create a lasting impression with a professionally designed, user-friendly website. Our web design and development services guarantee that your site not only looks stunning but also functions smoothly for every user experience.</p>
                </div>

                <div className="service-boxarea" data-aos="zoom-in" data-aos-duration="1200">
                  <span>Digital Marketing</span>
                  <div className="space66"></div>
                  <img src={Images.social_media_ads} alt="Local SEO Biharsharif" />
                  <div className="space40"></div>
                  <p>Digital marketing leverages social media, search engines, and email to promote brands and reach target audiences. Increase visibility, engage customers, and drive conversions with strategies tailored to your business.</p>
                </div>

                <div className="service-boxarea box2" data-aos="zoom-in" data-aos-duration="800">
                  <span>Search Engine Optimization (SEO)</span>
                  <div className="space40"></div>
                  <img src={Images.search_engine_optimization} alt="PPC Services Biharsharif" />
                  <div className="space40"></div>
                  <p>Enhance your online visibility and drive organic traffic with our advanced SEO strategies. We meticulously optimize your website to achieve higher search engine rankings and attract more potential customers to your business.</p>
                </div>

                <div className="service-boxarea box3" data-aos="zoom-in" data-aos-duration="1000">
                  <span>Mobile App<br /> Development</span>
                  <div className="space40"></div>
                  <img src={Images.mobile_app_development} alt="Content Marketing Biharsharif" />
                  <div className="space40"></div>
                  <p>Our agency provides top-tier mobile app development services across the UAE, Delhi/NCR, and Bihar. Known for our professionalism, we deliver tailored solutions to meet client needs with innovation and quality.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-lg-12">
              <div className="service-all-boxes-area">
                <div className="service-boxarea box4" data-aos="zoom-in" data-aos-duration="1400">
                  <span>Facebook Ads</span>
                  <div className="space40"></div>
                  <img src={Images.facebook_ads} alt="Online Marketing Biharsharif" />
                  <div className="space40"></div>
                  <p>Facebook Ads enable businesses to create targeted ads based on demographics and interests. Choose from formats like images, videos, or carousels, while gaining budget control, insights, and powerful retargeting features.</p>
                </div>

                <div className="service-boxarea" data-aos="zoom-in" data-aos-duration="800">
                  <span>Youtube Ads</span>
                  <div className="space40"></div>
                  <img src={Images.youtube_ads} alt="PPC Services Biharsharif" />
                  <div className="space40"></div>
                  <p>YouTube ads effectively reach large audiences through formats like skippable videos and bumper ads. They enable targeted marketing, helping brands connect with groups to boost visibility and website traffic.</p>
                </div>

                <div className="service-boxarea box2" data-aos="zoom-in" data-aos-duration="1000">
                  <span>Instagram Ads</span>
                  <div className="space40"></div>
                  <img src={Images.instagram_ads} alt="Content Marketing Biharsharif" />
                  <div className="space40"></div>
                  <p>Instagram ads allow businesses to reach a wide audience through photos, videos, and stories. With precise targeting, they boost brand visibility, engage users, and drive traffic to your products or services effectively.</p>
                </div>

                <div className="service-boxarea box3" data-aos="zoom-in" data-aos-duration="1200">
                  <span>Social Media Marketing</span>
                  <div className="space40"></div>
                  <img src={Images.social_media_marketing} alt="Local SEO Biharsharif" />
                  <div className="space40"></div>
                  <p>Build a robust brand presence and connect with your audience on social media. Our team creates strategic campaigns to enhance engagement and foster growth for your brand across various platforms.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="service2-section-area sp6" id="work">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 m-auto">
              <div className="service2-header heading2 text-center">
                <img src={require("../assets/img/elements/star2.png")} alt="Digital Strategy Biharsharif" className="star2 keyframe5" />
                <img src={require("../assets/img/elements/star2.png")} alt="Best Website Designing Company Biharsharif" className="star3 keyframe5" />
                <h3 className="text-anime-style-3">Tailored Solutions, Proven Results, <br className="d-md-block d-none" /> And Exceptional Service</h3>
                <p data-aos="fade-up" data-aos-duration="1000">We pride ourselves on delivering a value proposition that goes beyond expectations. Our <br className="d-md-block d-none" /> approach is centered on understanding your business inside</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-7">
              <div className="images-content-area" data-aos="zoom-in" data-aos-duration="1000">
                <div className="img1">
                  <img src={require("../assets/img/all-images/service-img1.webp")} alt="Top Website Designing Company Biharsharif" />
                </div>
                <div className="content-area">
                  <h5>Our Value</h5>
                  <ScrollTag slideTo="/" classList="text text-anime-style-3" btnVal="At Digital Mind™️, our unique value lies in our commitment to delivering tailored solutions that drive real business growth." />
                  <p data-aos="fade-up" data-aos-duration="1000">We recognize that every business is different, and we take a personalized approach to every project, ensuring that our strategies align with your unique goals and vision for success.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="service-all-boxes p-0">
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="service2-auhtor-boxarea" data-aos="zoom-out" data-aos-duration="1000">
                      <div className="content-area">
                        <h5>Our Mission</h5>
                        <ScrollTag slideTo="/" classList="" btnVal="At Digital Mind™, our mission is to go beyond delivering services—we aspire to be your trusted SEO and Digital Marketing partner." />
                        <p>We are committed to empowering businesses to grow, innovate, and excel in the digital world, unlocking their full potential with every step forward.</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-6">
                    <div className="service2-auhtor2-boxarea" data-aos="zoom-out" data-aos-duration="1200">
                      <div className="content-area">
                        <h5>Our Vision</h5>
                        <ScrollTag slideTo="/" classList="" btnVal="At Digital Mind™️, we envision a world where every business owner feels empowered to thrive in the digital space." />
                        <p>We aspire to make online success accessible to all, fostering growth, innovation, and long-term impact. By staying true to our vision and values, we are committed to driving positive change and shaping a brighter future for businesses and communities alike.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Service;
