import React, { useEffect, useState, useRef  } from "react";
import Images from "../components/Images"; // assuming your image import path is correct

const Preloader = () => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleWindowLoad = () => {
      setIsLoading(false);
    };

    window.addEventListener("load", handleWindowLoad);

    return () => {
      window.removeEventListener("load", handleWindowLoad);
    };
  }, []);

  const progressWrapRef = useRef(null);
  const progressPathRef = useRef(null);

  useEffect(() => {
    const progressPath = progressPathRef.current;
    const pathLength = progressPath.getTotalLength();

    // Initial path setup
    progressPath.style.transition = "none";
    progressPath.style.strokeDasharray = `${pathLength} ${pathLength}`;
    progressPath.style.strokeDashoffset = pathLength;
    progressPath.getBoundingClientRect();
    progressPath.style.transition = "stroke-dashoffset 10ms linear";

    const updateProgress = () => {
      const scroll = window.scrollY;
      const height = document.documentElement.scrollHeight - window.innerHeight;
      const progress = pathLength - (scroll * pathLength) / height;
      progressPath.style.strokeDashoffset = progress;
    };

    updateProgress();

    window.addEventListener("scroll", updateProgress);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("scroll", updateProgress);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const progressWrap = progressWrapRef.current;
      const offset = 50;

      if (window.scrollY > offset) {
        progressWrap.classList.add("active-progress");
      } else {
        progressWrap.classList.remove("active-progress");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (event) => {
    event.preventDefault();
    window.scrollTo({ top: 0, behavior: "smooth" });
  };


  return (
    <>
      {isLoading && (
        <div className="preloader">
          <div className="loading-container">
            <div className="loading"></div>
            <div id="loading-icon">
              <img src={Images.preloader} alt="Loading" />
            </div>
          </div>
        </div>
      )}
      <div className="paginacontainer">
        <div className="progress-wrap" ref={progressWrapRef} onClick={handleClick}>
           <svg className="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
             <path ref={progressPathRef} d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"/>
           </svg>
        </div>
      </div>
    </>
  );
};

export default Preloader;