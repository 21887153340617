import React,{useEffect} from "react";
import Header from "../components/Header";
import {TextAnimation} from "../components/TextAnimation";
import {About} from "../components/About";
import Aboutus from "../components/Aboutus";
import Service from "../components/Service";
import Images from "../components/Images";
import Preloader from "../components/Preloader";
import {Team} from "../components/Casearea";
import {Testimonial2,Testimonial3} from "../components/Testimonials";
import Footer from "../components/Footer";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link as ScrollLink } from 'react-scroll';

const Home = () => {
	useEffect(() => {
	    AOS.init({
	      disable: 'mobile', // Disable animations on mobile
	    });

	    // Optionally refresh AOS to trigger animations on content updates
	    AOS.refresh();
	  }, []); // Empty dependency array ensures this effect runs once
	return (
		<>
			<Preloader />
			<Header />
			<div className="hero1-section-area" id="hero1">
			  <img src={Images.elements1} alt="Digital Marketing Biharsharif" className="elements1 aniamtion-key-1"/>
			  <div className="container">
			    <div className="row align-items-center">
			      <div className="col-lg-6">
			        <div className="header-main-content heading1">
			          <h5><img src={Images.logoicons} alt="Top #1 SEO & Marketing Agency"/>Top #1 SEO & Marketing Agency</h5>
			          <TextAnimation />
			          <p data-aos="fade-left" data-aos-duration="1000">Welcome to Digital Mind™️ We are proud to be based in the vibrant cities of Dubai, UAE, Delhi-NCR, Bihar, and Biharsharif, India. Our team is dedicated to providing reliable and creative web design and digital marketing solutions.</p>
			          <div className="btn-area" data-aos="fade-left" data-aos-duration="1200">
			            <ScrollLink to="contact"  spy={true} smooth={true} offset={-70} duration={500} className="header-btn2" style={{cursor:'pointer'}}>Contact Now <span><i className="fa-solid fa-arrow-right"></i></span></ScrollLink>
			          </div>
			        </div>
			      </div>
			      <div className="col-lg-6">
			        <div className="header-images-area">
			          <div className="main-images-area">
			            <div className="img1">
			              <img src={Images.headerimg} alt="Social Media Marketing Biharsharif" data-aos="zoom-in" data-aos-duration="1000"/>
			            </div>
			            <div className="img2">
			              <img src={Images.headerimgbg} alt="SEO Services Biharsharif"/>
			            </div>
			            <div className="icons-area">
			              <img src={Images.soundicon} alt="Website Development Biharsharif" className="sound-icons1 aniamtion-key-1"/>
			              <img src={Images.liteicon} alt="Digital Advertising Biharsharif" className="lite-icons1 aniamtion-key-1"/>
			            </div>
			            <div className="auhtor-icons">
			              <img src={Images.elements2} alt="Online Marketing Biharsharif" className="elements2"/>
			              <img src={Images.elements3} alt="PPC Services Biharsharif" className="elements3"/>
			            </div>
			            <div className="auhtor-images">
			              <img src={Images.hedaerauthorimg1} alt="Content Marketing Biharsharif" className="header-author-img1 aniamtion-key-2"/>
			              <img src={Images.hedaerauthorimg2} alt="Local SEO Biharsharif" className="header-author-img2 aniamtion-key-2"/>
			            </div>
			          </div>
			        </div>
			      </div>
			    </div>
			  </div>
			</div>
			<About/>
			<Aboutus/>
			<Service/>
			<Team/>
			<Testimonial3/>
			<Testimonial2/>
			<Footer/>
		</>
	)
}
export default Home;