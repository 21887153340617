import React from "react";
import Images from "./Images";
import { Link as ScrollLink } from 'react-scroll';

const Footer = () => {
	return (
		<>
		<div className="cta-section-area">
		  <img src={require("../assets/img/bg/cta-bg1.png")} alt="Google PPC Campaigns Biharsharif" className="cta-bg1 aniamtion-key-2"/>
		  <img src={require("../assets/img/bg/cta-bg2.png")} alt="Local SEO Biharsharif" className="cta-bg2 aniamtion-key-1"/>
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-12 m-auto">  
		        <div className="cta-header-area text-center sp4 heading2">
		          <h2 className="text-anime-style-3">Ready To Take Your SEO To <br className="d-md-block d-none"/> The Next Level</h2>
		          <p data-aos="fade-up" data-aos-duration="1000">Effective SEO strategies not only elevate a website's visibility but also drive <br className="d-md-block d-none"/> targeted traffic, enhance user experience,</p>
		          <div className="btn-area text-center" data-aos="fade-up" data-aos-duration="1200">
		          <ScrollLink to="contact"  spy={true} smooth={true} offset={-70} duration={500} className="header-btn1">Free Consultation <span><i className="fa-solid fa-arrow-right"></i></span></ScrollLink>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
		<div className="footer1-section-area">
		  <div className="container">
		    <div className="row">
		      <div className="col-lg-3 col-md-6">
		        <div className="footer-logo-area">
			        <picture>
						<source type="image/webp" srcset={Images.logo1} />
						<img src={Images.logo1} alt="Website Development Biharsharif" />
					</picture>
		          <p>At Digital Mind™️, we empower your digital journey with innovative marketing and web design solutions, enhancing your brand's online presence and growth.</p>
		          <ul>
		            <li><a href="https://www.facebook.com/ourdigitalmind" target="_blank" rel="noreferrer"><img src={Images.facebook} alt="Best Website Designing Company Biharsharif"/></a></li>
		            <li><a href="https://www.instagram.com/ourdigitalmind/" target="_blank" rel="noreferrer"><img src={Images.instagram} alt="Top Website Designing Company Biharsharif"/></a></li>
		            <li><a href="https://www.linkedin.com/company/ourdigitalmind/" target="_blank" rel="noreferrer"><img src={Images.linkedin} alt="Number Website Company Biharsharif"/></a></li>
		          </ul>
		        </div>
		      </div>

		      <div className="col-lg-3 col-md-6">
		        <div className="footer-logo-area1">
		          <h3>About Link</h3>
		          <ul>
		            <li><ScrollLink to="hero1" spy={true} smooth={true} offset={-70} duration={500}>Home </ScrollLink></li>
					<li><ScrollLink to="aboutus" spy={true} smooth={true} offset={-70} duration={500}>About</ScrollLink></li>
					<li><ScrollLink to="service"  spy={true} smooth={true} offset={-70} duration={500}>Services</ScrollLink></li>
		            <li><ScrollLink to="work"  spy={true} smooth={true} offset={-70} duration={500}>Works</ScrollLink></li>
		            <li><ScrollLink to="team"  spy={true} smooth={true} offset={-70} duration={500}>Team</ScrollLink></li>
		            <li><ScrollLink to="testimonial"  spy={true} smooth={true} offset={-70} duration={500}>Testimonial</ScrollLink></li>
        			<li><ScrollLink to="contact"  spy={true} smooth={true} offset={-70} duration={500}>Contact</ScrollLink></li>
		            </ul>
		        </div>
		      </div>

		      <div className="col-lg-3 col-md-6">
		        <div className="footer-logo-area1">
		          <h3>Our Services</h3>
		          <ul>
		            <li><ScrollLink to="/" spy={true} smooth={true} offset={-70} duration={500}>Web Design & Developement </ScrollLink></li>
					<li><ScrollLink to="/" spy={true} smooth={true} offset={-70} duration={500}>Digital Marketing</ScrollLink></li>
					<li><ScrollLink to="/"  spy={true} smooth={true} offset={-70} duration={500}>SEO</ScrollLink></li>
		            <li><ScrollLink to="/"  spy={true} smooth={true} offset={-70} duration={500}>PPC Advertising</ScrollLink></li>
		            <li><ScrollLink to="/"  spy={true} smooth={true} offset={-70} duration={500}>Social Media Marketing</ScrollLink></li>
        			<li><ScrollLink to="/"  spy={true} smooth={true} offset={-70} duration={500}>Content Marketing</ScrollLink></li>
        			<li><ScrollLink to="/"  spy={true} smooth={true} offset={-70} duration={500}>Email Marketing</ScrollLink></li>
		            </ul>
		        </div>
		      </div>

		      <div className="col-lg-3 col-md-6">
		        <div className="footer-logo-area2">
		          <h3>Get in touch</h3>
		          <ul>
		            <li><a href="mailto:info@ourdigitalmind.com"><img src={Images.email} alt="PPC Services Biharsharif"/><span>info@ourdigitalmind.com</span></a></li>
		            <li><a href="/"><img src={Images.location} alt="Local SEO Biharsharif"/><span>A-60, 2nd Floor, Sector 2, Near Sector 15 Metro <br className="d-lg-block d-none"/>Noida,  <br className="d-lg-block d-none"/> Uttar Pradesh - 201301</span></a></li>
		            <li><a href="tel:+917488428886"><img src={Images.phone} alt="SEO Services Biharsharif"/><span>+91-7488428886</span></a></li>
		          </ul>
		        </div>
		      </div>
		    </div>
		    <div className="space80 d-lg-block d-none"></div>
		    <div className="space40 d-lg-none d-block"></div>
		    <div className="row">
		      <div className="col-lg-12">
		        <div className="copyright-area">
		          <div className="pera">
		            <p>ⓒ Copyright 2024 Digital Mind . All rights reserved</p>
		          </div>
		        </div>
		      </div>
		    </div>
		  </div>
		</div>
		</>
	)
}
export default Footer;